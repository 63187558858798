import React, { useState, useEffect } from "react"

import { Typography, Container } from '@mui/material';

import Layout from "../components/layout"
import Seo from "../components/seo"

const SubmittedPage = () => {
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    useEffect(() => {
        // console.log(window.history.state);
        if (typeof window.history.state.title !== `undefined`) {
            setTitle(window.history.state.title);
        }
        if (typeof window.history.state.message !== `undefined`) {
            setMessage(window.history.state.message);
        }
    }, [])
    return (
        <Layout>
            <Seo title="会員登録" robotsType="noindex" />
            <Container maxWidth="sm">
                <Typography variant="h2">{title}</Typography>
                <Typography variant="body1">{message}</Typography>
            </Container>
        </Layout>
    );
}

export default SubmittedPage